import {
	operates,getConsultGoods
} from '@/api/cdm/auth.js'

import {
	agentList
} from '@/api/cdm/cdm-permission.js'

const state = {
  operates:[],
  permissions:[],
  agents:[],
  consult_goods:[]
}

const mutations = {
	SET_OPERATES:(state,operates) =>{
		state.operates = operates
	},
	SET_AGENTS:(state,agents) =>{
		state.agents = agents
	},
	SET_CONSULT_GOODS:(state,goods) =>{
		state.consult_goods = goods
	}
}


const actions = {
	getConsuleGoods({commit}){
		let agents = window.sessionStorage.getItem('CONSULT_GOODS');
		if(agents){
			commit('SET_CONSULT_GOODS',JSON.parse(agents))
		}else{
			getConsultGoods().then(response=>{
				if (response.code == 200) { 
					commit('SET_CONSULT_GOODS', response.data)
					sessionStorage.setItem('CONSULT_GOODS', JSON.stringify(response.data))
					
				} 		
			})
		}
	},
	// 获取机构列表
	getAgents({
		commit
	}){
		let agents = window.sessionStorage.getItem('AGENTS');
		if(agents){

			commit('SET_AGENTS',JSON.parse(agents))
		}else{
			agentList().then(response => {
				if (response.code == 200) { 
					commit('SET_AGENTS', response.data)
					sessionStorage.setItem('AGENTS', JSON.stringify(response.data))
					
				} 
			})
		}
	},
	// 增加机构
	addAgents({commit},agent){
		let agents = window.sessionStorage.getItem('AGENTS');
		agents = agents ? actions : []
		agents = agents.push({id:agent.id,merchant_name:agent.merchant_name})
		sessionStorage.setItem('AGENTS', JSON.stringify(agents))
		commit('SET_AGENTS', agents)
	},
	// 获取权限操作选项
	getOperates({
		commit
	}) {
		let operate = window.sessionStorage.getItem('OPERATES');
		if(operate){
			commit('SET_OPERATES',JSON.parse(operate))
		}else{
			operates().then(response => {
				console.log('99999',response)
				if (response.code == 200) { 
					commit('SET_OPERATES', response.data)
					sessionStorage.setItem('OPERATES', JSON.stringify(response.data))
					
				} 
			})
		}
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
