<template>
	<div class="">
		<div class="bell-wrapper">
			<el-badge :hidden="messageStatistics.count === 0"  :value="messageStatistics.count && messageStatistics.count.toString()" class="item" type="danger">
				<el-button size="mini" type="text" @click="handleBell">
					<i class="iconfont el-icon-message-solid"></i>
				</el-button>
			</el-badge>
		</div>
		<!--drawer-->
		<el-drawer
			title=""
			v-loading="listLoading"
			:visible.sync="drawer"
			:size="650"
			:with-header="false">
			<div class="drawer-header">
				<div class="title">通知</div>
			</div>
			<div class="drawer-navbar">
				<div class="drawer-navbar-item">
					<el-badge :hidden="messageStatistics.count === 0" :value="messageStatistics.count && messageStatistics.count.toString()" class="item">
						<el-button size="mini" :type="isAllActiveBtn" round @click="handleAllisActive">全部</el-button>
					</el-badge>
				</div>
				<div class="drawer-navbar-item">
					<el-badge :hidden="messageStatistics.count === 0" :value="messageStatistics.systemCount && messageStatistics.systemCount.toString()" class="item">
						<el-button size="mini" :type="isSystemActiveBtn" round  @click="handleSystemIsActive">系统</el-button>
					</el-badge>
				</div>
				<div class="drawer-navbar-item" @click="handleAnnouncementIsActive">
					<el-badge :hidden="messageStatistics.count === 0" :value="messageStatistics.noticeCount && messageStatistics.noticeCount.toString()" class="item">
						<el-button size="mini" :type="isAnnouncementIsActiveBtn" round>公告</el-button>
					</el-badge>
				</div>
			</div>
			<!--全部消息列表-->
			<div class="drawer-body drawer-body-all" v-if="isAllActive">
				<div class="drawer-body-item"  v-for="(item, index) in message" :key="item.id">
					<el-row style="margin-bottom: 5px">
						<el-col :span="2"><div class="grid-content bg-purple" style="display: flex;justify-content: center">
							<el-button type="primary" icon="el-icon-s-order" circle></el-button>
						</div></el-col>
						<el-col :span="18"><div class="grid-content bg-purple-light" style="font-weight: bold; font-size: 14px; color: #555; line-height: 30px">
							{{item.title}}
						</div></el-col>
						<el-col :span="4"><div class="grid-content bg-purple" style="display: flex; justify-content: center; font-size: 14px; color: #777; line-height: 30px">
							<span>{{item.created_at}}</span>
							<span v-show="item.is_read === 0" style="margin-left: 10px; color: red;line-height: 20px;padding-top: 5px">*</span>
						</div></el-col>
					</el-row>
					<el-row >
						<div class="stop-capture">
							<el-collapse v-model="activeNames" accordion>
							<el-collapse-item :name="index">
								<div slot="title" style="display: flex; margin-left: 470px; justify-content: right;align-items: center">
										<el-button type="text" size="mini" @click="handleShowDetail(item)">查看详情</el-button>
									<div @click="handleMarkRead">
										<el-button type="text" size="mini" @click="handleMarkRead(item, $event)" :disabled="item.is_read === 1">标记已读</el-button>
									</div>
									<div @click="handleDeleteMessage">
										<el-button type="text" size="mini" @click="handleDeleteMessage(item, $event)">删除</el-button>
									</div>
								</div>
								<div style="padding: 6px" v-html="infoDetail"></div>
							</el-collapse-item>
						</el-collapse>
						</div>
					</el-row>
				</div>
			</div>
			<!--系统消息列表-->
			<div class="drawer-body drawer-body-system" v-if="isSystemActive">
				<div class="drawer-body-item">
					<el-row>
						<el-col :span="2"><div class="grid-content bg-purple" style="display: flex;justify-content: center">
							<el-button type="primary" icon="el-icon-s-order" circle></el-button>
							<!--<i class="el-icon-s-claim"></i>-->
						</div></el-col>
						<el-col :span="18"><div class="grid-content bg-purple-light" style="font-weight: bold; font-size: 14px; color: #555; line-height: 30px">
							没有数据, 测试数据,没有数据, 测试数据,没有数据, 测试数据
						</div></el-col>
						<el-col :span="4"><div class="grid-content bg-purple" style="display: flex; justify-content: center; font-size: 14px; color: #777; line-height: 30px">
							<span>11月28号</span>
							<span style="margin-left: 10px; color: red;line-height: 20px;padding-top: 10px">*</span>
						</div></el-col>
					</el-row>
					<el-row>
						<el-col :span="14"><div class="grid-content bg-purple"></div></el-col>
						<el-col :span="10"><div class="grid-content bg-purple-light drawer-body-item-footer" style="display: flex; justify-content: space-around; align-items: center">
							<el-button type="text" size="mini">查看详情</el-button>
							<el-button type="text" size="mini">标记已读</el-button>
							<el-button type="text" size="mini">删除</el-button>
						</div></el-col>
					</el-row>
				</div>
				<div class="drawer-body-item">
					<el-row>
						<el-col :span="2"><div class="grid-content bg-purple" style="display: flex;justify-content: center">
							<el-button type="primary" icon="el-icon-s-order" circle></el-button>
							<!--<i class="el-icon-s-claim"></i>-->
						</div></el-col>
						<el-col :span="18"><div class="grid-content bg-purple-light" style="font-size: 14px; color: #777; line-height: 30px">
							没有数据, 测试数据
						</div></el-col>
						<el-col :span="4"><div class="grid-content bg-purple" style="display: flex; justify-content: center; font-size: 14px; color: #777; line-height: 30px">
							<span>11月28号</span>
							<span style="margin-left: 10px; color: red;line-height: 20px;padding-top: 10px">*</span>
						</div></el-col>
					</el-row>
					<el-row>
						<el-col :span="14"><div class="grid-content bg-purple"></div></el-col>
						<el-col :span="10"><div class="grid-content bg-purple-light drawer-body-item-footer" style="display: flex; justify-content: space-around; align-items: center">
							<el-button type="text" size="mini">查看详情</el-button>
							<el-button type="text" size="mini">标记已读</el-button>
							<el-button type="text" size="mini">详情</el-button>
						</div></el-col>
					</el-row>
				</div>
				<div class="drawer-body-item">
					<el-row>
						<el-col :span="2"><div class="grid-content bg-purple" style="display: flex;justify-content: center">
							<el-button type="primary" icon="el-icon-s-order" circle></el-button>
							<!--<i class="el-icon-s-claim"></i>-->
						</div></el-col>
						<el-col :span="18"><div class="grid-content bg-purple-light" style="font-size: 14px; color: #777; line-height: 30px">
							没有数据, 测试数据
						</div></el-col>
						<el-col :span="4"><div class="grid-content bg-purple" style="display: flex; justify-content: center; font-size: 14px; color: #777; line-height: 30px">
							<span>11月28号</span>
							<span style="margin-left: 10px; color: red;line-height: 20px;padding-top: 10px">*</span>
						</div></el-col>
					</el-row>
					<el-row>
						<el-col :span="14"><div class="grid-content bg-purple"></div></el-col>
						<el-col :span="10"><div class="grid-content bg-purple-light drawer-body-item-footer" style="display: flex; justify-content: space-around; align-items: center">
							<el-button type="text" size="mini">查看详情</el-button>
							<el-button type="text" size="mini">标记已读</el-button>
							<el-button type="text" size="mini">详情</el-button>
						</div></el-col>
					</el-row>
				</div>
			</div>
			
			<!--公告消息列表-->
			<div class="drawer-body drawer-body-announcement" v-if="isAnnouncementIsActive">
				<div class="drawer-body-item"  v-for="(item, index) in message" :key="item.id">
					<el-row style="margin-bottom: 5px">
						<el-col :span="2"><div class="grid-content bg-purple" style="display: flex;justify-content: center">
							<el-button type="primary" icon="el-icon-s-order" circle></el-button>
						</div></el-col>
						<el-col :span="18"><div class="grid-content bg-purple-light" style="font-weight: bold; font-size: 14px; color: #555; line-height: 30px">
							{{item.title}}
						</div></el-col>
						<el-col :span="4"><div class="grid-content bg-purple" style="display: flex; justify-content: center; font-size: 14px; color: #777; line-height: 30px">
							<span>{{item.created_at}}</span>
							<span v-show="item.is_read === 0" style="margin-left: 10px; color: red;line-height: 20px;padding-top: 5px">*</span>
						</div></el-col>
					</el-row>
					<el-row >
						<div class="stop-capture">
							<el-collapse v-model="activeNames" accordion>
								<el-collapse-item :name="index">
									<div slot="title" style="display: flex; margin-left: 470px; justify-content: right;align-items: center">
										<el-button type="text" size="mini" @click="handleShowDetail(item)">查看详情</el-button>
										<div @click="handleMarkRead">
											<el-button type="text" size="mini" @click="handleMarkRead(item, $event)" :disabled="item.is_read === 1">标记已读</el-button>
										</div>
										<div @click="handleDeleteMessage">
											<el-button type="text" size="mini" @click="handleDeleteMessage(item, $event)">删除</el-button>
										</div>
									</div>
									<div style="padding: 6px" v-html="infoDetail"></div>
								</el-collapse-item>
							</el-collapse>
						</div>
					</el-row>
				</div>
		</div>
			
			<div class="foot-bar">
				<el-switch
					style="display: block"
					v-model="switchMessageVisible"
					active-color="#2362fb"
					inactive-color="#cecece"
					:active-value="0"
					:inactive-value="1"
					active-text="仅显示未读消息"
					@change="handleMessageUnread"
					inactive-text="">
				</el-switch>
				<el-dropdown trigger="click" @command="handleDropDownCommand">
					<span class="el-dropdown-link">
						• • •
					</span>
					<el-dropdown-menu slot="dropdown" >
						<el-dropdown-item command="allRead">
							<i class="el-icon-check dropdown-icon"></i>
							<span class="dropdown-title">全部标记为已读</span>
						</el-dropdown-item>
						<el-dropdown-item command="delAllRead">
							<i class="el-icon-delete dropdown-icon"></i>
							<span class="dropdown-title">删除全部已读消息</span>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			
			<!--分页pagination-->
			<div style="position: fixed; bottom: 21px; z-index: 2;padding: 0 20px 0 20px;background-color: #fff">
			<pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
									@pagination="_getMessageList"  />
			</div>
		</el-drawer>
		
	</div>
</template>

<script>
import {getMessageList,
	getMessageInfo,
	postMessageRead,
	delMessageDelMessage,
	delMessageDelAllRead,
  postMessageDelAllRead} from '@/api/systemManage'
import pagination from '@/components/Pagination'

export default {
  name: "Bell",
	components: {
    pagination
	},
	async created () {
    await this._getMessageCount('visible')
  },
	computed: {
    message() {
      if (this.switchMessageVisible === 1) {
        return this.messageList
			} else {
        return this.messageList.filter((item,index,arr) => {
          return item.is_read === 0
        })
			}
		}
	},
  data() {
    return {
      drawer: false,
      isAllActiveBtn: 'primary',
      isSystemActiveBtn: '',
      isAnnouncementIsActiveBtn: '',
      isAllActive: true,
			isSystemActive: false,
			isAnnouncementIsActive: false,
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id',
      },
      messageList: [],
      messageStatistics: this.$store.getters.noticeCount,
      switchMessageVisible: 1,
      messageListUnread: [],
      activeNames: [],
      infoDetail: '',
		}
	},
	watch:{
		'$store.state.websocket.messageList':{
			handler:async function(){
				let message =  this.$store.state.websocket.messageList
				let lastMsg = message.slice(-1)[0]
				console.log("=====接受websocket===",lastMsg)
				if(lastMsg.type == 'message_notice'){
					this.$notify({
						title: '提示',
						message: '您有一条新消息请注意查收',
						duration: 0
					});
          this._getMessageCount('visible')
				}else if(lastMsg.type == "offline"){
					await this.$store.dispatch('user/logout')
					this.$alert('检测到该账号已在其他设备登录,已被强制下线', '强制下线', {
						confirmButtonText: '确定',
						showClose:false,
						callback: action => {
							this.$router.push(`/login?redirect=${this.$route.fullPath}`)
						}
					});
				}else if(lastMsg.type == 'close_windows'){
					
					this.$message({
						type:"error",
						message: '您已被管理员强制下线',
						duration:1000,
						onClose:async ()=>{
							await this.$store.dispatch('user/logout')
							if(navigator.userAgent.indexOf("Firefox") != -1 || navigator.userAgent.indexOf("Chrome") != -1){
								window.location.href = "about:blank";
								window.close();
							}else{
								window.opener = null;
								window.open("", "_self");
								window.close();
							}
						}
					})
					
				}
			}
		}
	},
	methods: {
    _getMessageList(rest) {
      if (rest === 'restPage') {
        this.listQuery.page = 1
      }
      this.listLoading = true
      let params = {
        ...this.searchParams
      }
      params.page = this.listQuery.page
      params.page_size = this.listQuery.limit
      getMessageList(params).then(response => {
        let data = response
        console.log(response)
        if (data.code === 200) {
          let _data = data.data
          this.total = _data.total
          this.messageList = _data.list
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    async _getMessageCount(state) {
      // if (this.intervalId) {
      //   clearInterval(this.intervalId)
      //   this.intervalId = null
      // }
      // if (state === 'visible') {
      //   await this._getMessageCount()
      //   await this.$store.dispatch('user/getNoticeCount')
      //   this.$forceUpdate()
      //   this.intervalId = setInterval(() => {
      //     this.$store.dispatch('user/getNoticeCount')
			// 		this.$forceUpdate()
      //   }, 60000)
      // }

      if (state === 'visible') {
        await this._getMessageCount()
        await this.$store.dispatch('user/getNoticeCount')
        this.$forceUpdate()
      }
    },
    _getMessageInfo(id) {
      this.listLoading = true
      getMessageInfo(id).then(response => {
        if (response.code === 200) {
          this.infoDetail = response.data.content
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
		_postMessageRead(id) {
      this.listLoading = true
      postMessageRead(id).then(async response => {
        if (response.code === 200) {
          this.$message({
            type: 'success',
            message: '已读标记!'
          })
          await this.$store.dispatch('user/getNoticeCount')
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.$message({
          type: 'error',
          message: '已读标记失败!'
        });
        this.listLoading = false
      })
		},
		_postMessageDelAllRead() {
      this.listLoading = true
      postMessageDelAllRead().then(response => {
        if (response.code === 200) {
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
          this._getMessageList()
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.$message({
          type: 'error',
          message: '修改失败!'
        });
        this.listLoading = false
      })
		},
		_delMessageDelMessage(id) {
      this.listLoading = true
      delMessageDelMessage(id).then(response => {
        if (response.code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this._getMessageList()
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.$message({
          type: 'error',
          message: '删除失败!'
        });
        this.listLoading = false
      })
    },
		_delMessageDelAllRead() {
      this.listLoading = true
      delMessageDelAllRead().then(response => {
        if (response.code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this._getMessageList()
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.$message({
          type: 'error',
          message: '删除失败!'
        });
        this.listLoading = false
      })
    },
    async handleBell() {
      this.drawer = !this.drawer
			await this._getMessageList()
    },
    handleMessageUnread() {
      console.log(this.switchMessageVisible)
		},
    handleAllisActive() {
    	this.isSystemActive = this.isAnnouncementIsActive = false
			this.isSystemActiveBtn = this.isAnnouncementIsActiveBtn = ''
			this.isAllActive = true
			this.isAllActiveBtn = 'primary'
		},
    handleSystemIsActive() {
      console.log('####')
      this.isAllActive = this.isAnnouncementIsActive = false
      this.isAllActiveBtn = this.isAnnouncementIsActiveBtn = ''
      this.isSystemActive = true
      this.isSystemActiveBtn = 'primary'
		},
    handleAnnouncementIsActive() {
      this.isSystemActive = this.isAllActive = false
      this.isSystemActiveBtn = this.isAllActiveBtn = ''
      this.isAnnouncementIsActive = true
      this.isAnnouncementIsActiveBtn = 'primary'
		},
    handleDropDownCommand(command) {
      if (command === 'delAllRead') {
        this.$confirm('确认删除全部已读?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this._delMessageDelAllRead()
         	this.$forceUpdate()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
			} else {
        this.$confirm('确认全部标记已读?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this._postMessageDelAllRead()
          this.$forceUpdate()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
			}
		},
    handleShowDetail(item) {
      this.infoDetail = ''
			this._getMessageInfo(item.id)
			if (item.is_read === 1) return
			this._postMessageRead(item.id)
      item.is_read = 1
		},
    handleChange(val) {
      console.log(val)
      this.context = "close";
		},
    handleMarkRead(item, e) {
			e.stopPropagation()
			this._postMessageRead(item.id)
      item.is_read = 1
		},
    handleDeleteMessage(item, e) {
      e.stopPropagation()
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
				this._delMessageDelMessage(item.id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
		},
    stopCapture(index) {
      console.log(this.$refs.stopCapture[index]);
    }
	}
}
</script>

<style scoped lang="scss">
	.bell-wrapper{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		margin-right: 10px;
	}
	.iconfont{
		color: #aaa;
		font-size: 20px;
		&:hover{
			cursor: pointer;
			color: #777;
		}
	}

	
	/*调整徽标提示的高度*/
	/deep/ .el-badge{
		margin-top: 10px;
	}
	/deep/ .el-badge__content{
		margin-top: 10px;
	}
	
	/*抽屉内容样式样式*/
	.drawer-header{
		font-size: 14px;
		font-weight: bold;
		color: #777;
		background-color: #f7f7f7;
		.title{
			margin-left: 20px;
			height: 50px;
			line-height: 55px;
		}
	}
	
	.drawer-navbar{
		margin-left: 10px;
		width: 35%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 5px;
		border-bottom: 1px	solid #f7f7f7;
	}
	
	/deep/ .drawer-body-item{
		padding-top: 5px;
		border-bottom: 1px	solid #f7f7f7;
		.el-button{
			display: flex;
			align-items: center;
			padding: 6px;
			
			.el-icon-s-order{
				font-size: 16px;
			}
		}
		&:hover{
			cursor: pointer;
			background-color: #f7f7f7;
		}
	}
	/deep/ .el-drawer.rtl{
		overflow: scroll;
	}
	/deep/ .el-drawer__container ::-webkit-scrollbar {
		display: none;
	}
	/*栅格排版用样式*/
	/*.bg-purple {*/
	/*	background: #d3dce6;*/
	/*}*/
	/*.bg-purple-light {*/
	/*	background: #e5e9f2;*/
	/*}*/
	.grid-content {
		border-radius: 4px;
		min-height: 30px;
	}
	
	/*.drawer-body-item-footer{*/
	/*	/deep/ .el-button{*/
	/*		font-size: 12px;*/
	/*		font-weight: bold;*/
	/*		color: #777;*/
	/*	}*/
	/*}*/
	
	.drawer-body-item {
		min-height: 30px;
	}
	
	/*button颜色替换*/
	.drawer-navbar-item {
		.item{
			/deep/ .el-button--primary{
				background-color: #2362FB;
				color: #fff;
				border-color:  #2362FB;
			}
		}
	}
	
	/deep/ .el-button--primary{
		background-color: #2362FB;
		color: #fff;
		border-color:  #2362FB;
	}
	
	/*主体距离底部的高度*/
	.drawer-body-all {
		margin-bottom: 105px;
	}
	
/*	!*手风琴*!
	/deep/ .el-collapse-item__arrow{
		display: none;
	}*/
	/deep/ .el-collapse{
		border-top: 0;
		border-bottom: 1px solid #f1f1f1;
	}
	
	/deep/ .el-collapse-item__header{
		height: 30px;
	}
	
	/*抽屉底部样式*/
	.foot-bar{
		z-index: 3;
		position: fixed;
		bottom: 0;
		width: 100%;
		display: flex;
		align-items: center;
		border-top: 1px solid #afafaf;
		background-color: #f1f1f1;
	}
	
	.el-switch{
		margin-left: 7px;
	}
	/deep/ .el-switch__label{
		font-weight: bold;
		color: #666;
	}
	
	.el-dropdown{
		margin-left: 450px;
	}
	.el-dropdown-link {
		cursor: pointer;
	}
	
	.dropdown-icon{
	
	}
	.dropdown-title{
		color: #777;
		font-weight: bold;
		margin-left: 6px;
	}
</style>
