import Vue from 'vue'
import vueToPdf from 'vue-to-pdf';
import './plugins/element.js'
import '@/styles/index.scss' // global css
import 'font-awesome/scss/font-awesome.scss' // https://fontawesome.dashgame.com/ 图标库
import App from './App.vue'
import router from './router'
import store from './store'

import './icons' // icon
import './font/cdm_font/iconfont.js' // 彩色图标 icon
import './permission'
import config from "@/utils/config"; // 权限控制

import Blob from './utils/blob.js'
import Export2Excel from './utils/Export2Excel.js'


import $ from 'jquery'
Vue.prototype.$ = $
Vue.config.productionTip = false
Vue.prototype.$echarts = window.echarts
Vue.use(vueToPdf);
// 时间处理 

import moment from 'moment'
Vue.prototype.$moment = moment

const {
	mockXHR
} = require('../mock')
mockXHR()

var reg = /(http:\/\/)(.*)(\/#)/
let matchUrl =  reg.exec(location.href)
matchUrl = matchUrl[2] ? matchUrl[2]+"/ws" : ''

if(matchUrl && !matchUrl.includes('localhost')){
	if(!matchUrl.includes('test')){
		store.dispatch('websocket/connect',`ws://${matchUrl}`)
	}
}else{
	store.dispatch('websocket/connect',`ws://cdm-webadmin.gegejk.com/ws`)
	// store.dispatch('websocket/connect',`ws://192.168.10.10:2347`)
}

Vue.config.productionTip = false

Vue.filter('filterCDNUrl', function(url) {
	if (!url) {
		return ''
	} else {
		if (url.indexOf('http') > -1) {
			return url
		} else {
			return config.qiniuUrl + url
		}
	}
})

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
