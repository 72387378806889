/*
*系统配置接口
*/

import request from '@/utils/request'


// 医师/药师列表
export function getButlerDoctorList(params) {
  return request({
    url: '/admin/butler/docList',
    method: 'GET',
    params
  })
}

//平台商户列表
export function getMerchants() {
    return request({
        url: '/admin/merchants',
        method: 'get'
    })
}

//诊断列表
export function getDiseaseList(params) {
    return request({
        url: '/admin/common/diseaseList',
        method: 'get',
		params
    })
}

//保存系统设置
export function saveConfig(params) {
    return request({
        url: '/admin/setting/save',
        method: 'post',
		data:params
    })
}

//系统设置详情
export function getSettingInfo(params) {
    return request({
        url: '/admin/setting/info',
        method: 'get',
		params
    })
} 
//获取进线商品列表
export function getGetThreadGoods(params) {
    return request({
        url: 'admin/getThreadGoods',
        method: 'get',
		params
    })
} 


