import router from './router'
import store from './store'
import {
	Message
} from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import {
	getToken
} from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({
	showSpinner: false
}) // NProgress Configuration

const whiteList = ['/login', '/auth-redirect'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
	// start progress bar
	NProgress.start()
	// set page title
	document.title = getPageTitle(to.meta.title)

	// determine whether the user has logged in
	const hasToken = getToken()
	if (hasToken) {
		if (to.path === '/login') {
			// if is logged in, redirect to the home page
			next({
				path: '/'
			})
			NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
		} else {
			// determine whether the user has obtained his permission roles through getInfo
			const hasRoles = store.getters.roles && store.getters.roles.length > 0
			const addRoutes = store.state.permission.addRoutes[0]
			console.log(22222222,hasRoles,addRoutes)
			if (hasRoles) {

				if(to.path == "/"){
					if(store.state.permission.addRoutes.find(v => v.name == 'dashboard')){
						next()
					}else{
						next(addRoutes.name + '/' + addRoutes.children[0].path)
					}
				}else{
					next()
				}
			} else {
				try {
					// get user info
					// note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
					const {
						permission
					} = await store.dispatch('user/getInfo')
          console.log(permission)
					// const user = await store.dispatch('user/getInfo')
					// generate accessible routes map based on roles
					const accessRoutes = await store.dispatch('permission/generateRoutes', permission)
					// dynamically add accessible routes
          console.log(accessRoutes)
					router.addRoutes(accessRoutes)
					// hack method to ensure that addRoutes is complete
					// set the replace: true, so the navigation will not leave a history record
					next({
						...to,
						replace: true
					})
				} catch (error) {
					// remove token and go to login page to re-login
					await store.dispatch('user/resetToken')
					Message.error(error || 'Has Error')
					next(`/login?redirect=${to.path}`)
					NProgress.done()
				}
			}
		}
	} else {
		console.log("token不存在")
		/* has no token*/

		if (whiteList.indexOf(to.path) !== -1) {
			// in the free login whitelist, go directly
			next()
		} else {
			// other pages that do not have permission to access are redirected to the login page.
			next(`/login?redirect=${to.path}`)
			NProgress.done()
		}
	}
})

router.afterEach(() => {
	// finish progress bar
	NProgress.done()
})
