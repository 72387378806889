const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  userType: state => state.user.userType,
  adminId: state => state.user.adminId,
  agentId: state => state.user.agentId,
  pharmacyIntro: state => state.user.pharmacyIntro,
  permission_routes: state => state.permission.routes,
  noticeCount: state => state.user.noticeCount,
  routeList: state => state.user.routeList,
  isActiveMenuBar: state => state.user.isActiveMenuBar
}
export default getters
