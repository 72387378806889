import {
	operates,
	getPermissions,
	getAgents
} from '@/api/cdm/auth.js'

import {
	agentList
} from '@/api/cdm/cdm-permission.js'

const state = {
  operates:[],
  permissions:[],
  agents:[]
}

const mutations = {
	SET_OPERATES:(state,operates) =>{
		state.operates = operates
	},
	SET_PERMISSIONS:(state,permissions) =>{
		state.permissions = permissions
	},
	SET_AGENTS:(state,agents) =>{
		state.agents = agents
	}
}


const actions = {
	getAgents({
		commit
	}){
		let agents = window.sessionStorage.getItem('AGENTS');
		if(agents){

			commit('SET_AGENTS',JSON.parse(agents))
		}else{
			agentList().then(response => {
				if (response.code == 200) { 
					commit('SET_AGENTS', response.data)
					sessionStorage.setItem('AGENTS', JSON.stringify(response.data))
					
				} 
			})
		}
	},
	// user login
	// eslint-disable-next-line no-empty-pattern
	getOperates({
		commit
	}) {
		let operate = window.sessionStorage.getItem('OPERATES');
		if(operate){
			console.log(1111111111,JSON.parse(operate))
			commit('SET_OPERATES',JSON.parse(operate))
		}else{
			operates().then(response => {
				console.log('99999',response)
				if (response.code == 200) { 
					commit('SET_OPERATES', response.data)
					sessionStorage.setItem('OPERATES', JSON.stringify(response.data))
					
				} 
			})
		}
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
