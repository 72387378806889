import {
  login,
  getInfo,
  logout
} from '@/api/user'
import {
  Message,
  TimePicker
} from "element-ui";
import {
  setToken,
  getToken,
  removeToken
} from '@/utils/auth'
import {getJobCount} from '@/api/ggkq';
import {
  resetRouter,
  routesMap,
  asyncRoutes,
  constantRoutes,
  asyncRouterMap
} from '@/router'
import request from '@/utils/request';
import {
  getMessageCount
} from '@/api/systemManage'
import da from 'element-ui/src/locale/lang/da';
import {bindSocket} from '@/api/socket'

export function filterCustomRoutes (asyncRouterMap, channels) {
  let res = []
  channels.forEach(channel => {
    const tmp = []
    asyncRouterMap.forEach(route => {
      if (route.type === channel) {
        tmp.push(...route.router)
      }
    })
    res = [...res, ...tmp]
  })
  return res
}

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  adminId: '',
  roles: [],
  userType: '',
  pharmacyIntro: {},
  noticeCount: {},
  routeList: [],
  isActiveMenuBar: [],
  is_self: '',
  is_admin: '',
  agent_id: '',
  operates: [],
  table_options:{}
}

const mutations = {
  SET_IS_SELF: (state, self) => {
    state.is_self = self
  },
  SET_IS_ADMIN: (state, self) => {
    state.is_admin = self
  },
  SET_TOKEN: (state, token) => {
    state.token = token
    setToken(token)
    console.log("``````````token````````", token)
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_USER_TYPE: (state, type) => {
    state.userType = type
  },
  SET_AGENT_PID: (state, pid) => {
    state.agentPid = pid
  },
  SET_PHARMACY_INTRO: (state, params) => {
    state.pharmacyIntro = params
  },
  SET_USER_ID: (state, admin_id) => {
    state.adminId = admin_id
  },
  SET_AGENT_ID: (state, agent_id) => {
    state.agentId = agent_id
  },
  SET_NOTICE_COUNT: (state, notice) => {
    state.noticeCount.noticeCount = notice.noticeCount
    state.noticeCount.systemCount = notice.systemCount
    state.noticeCount.count = notice.count
  },
  SET_ROUTES_LIST: (state, routeList) => {
    state.routeList = routeList
  },
  SET_IS_ACTIVE_MENU_BAR: (state, isActiveMenuBar) => {
    state.isActiveMenuBar = isActiveMenuBar
  },
  SET_OPERATES: (state, operates) => {
    state.operates = operates
  },
  SET_TABLE_OPTIONS: (state, table_options) => {
    state.table_options = table_options
  }
}

export function filterAdminRoutes (asyncRoutes) {
  const res = []

  asyncRoutes.forEach(path => {
    const tmp = {
      ...path
    }
    if ((tmp.admin_hidden == undefined || !tmp.admin_hidden) &&  tmp.children) {
      tmp.children = tmp.children.filter(function (item) {
        return item.admin_hidden == undefined || !item.admin_hidden
      })
      res.push(tmp)
    }

  })

  return res
}

export function filterAsyncRoutes (serviceMap) {
  //服务端返回的路由与前端路由对比映射
  console.log(serviceMap);
  const res = []
  serviceMap.forEach(item => {
    if (routesMap[item.path]) {
      const tmp = {
        ...routesMap[item.path],
        children: item.children ? item.children : []
      }
      // console.log(tmp)
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children)
      }

      res.push(tmp)
    }
  })
  // console.log(res)
  return res
}

const actions = {
  // user login
  // eslint-disable-next-line no-empty-pattern
  login ({
           commit,rootState
         }, userInfo) {

    // const {loginName, password} = userInfo
    return new Promise((resolve, reject) => {
      userInfo.client_id = rootState.websocket.client_id
      login(userInfo).then(response => {
        let data = response.data
        console.log('99999', data)
        if (data.code == 401) { //密码错误就不再请求token
          resolve(data)
          Message({
            message: data.message,
            type: "error",
            duration: 5 * 1000,
          });
          console.log("账号密码错")
        } else {
          console.log(11111,rootState)
          // bindSocket({client_id:rootState.websocket.client_id})
          commit('SET_TOKEN', data.token)
          console.log(data)
          resolve(data)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo ({
             commit
           }, payload = ['internetHospitalBaseAsyncRoutes']) {
    return new Promise((resolve, reject) => {
      getInfo().then(async response => {
        if (response.code != 200) {
          reject('该用户没有配置权限角色1')
          return
        }

        let roles = []
        let permissions = []

        let operators = []
        let data = response.data
        console.log(data)
        data.permission.forEach(path => {
          if(path.children) {
            path.children.forEach(item => {
              let originOperators = {}
              var name = `/${path.path}/${item.path}`
              originOperators[name] = `${item.operates}`
              operators.push(originOperators)
            })
          }
        })
        commit('SET_TABLE_OPTIONS', data.options.table_options)
        commit('SET_OPERATES', operators)
        commit('SET_IS_SELF', data.is_self)
        commit('SET_USER_ID', data.id)

        commit('SET_IS_ADMIN', data.is_admin)
        // commit('SET_AGENT_ID', data.agent_id)
        commit('SET_AVATAR',
          'https://pic.hngegejk.cn/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20201016161303.png'
        )
        commit('SET_NAME', data.name)

        commit('SET_USER_TYPE', data.type)
        // commit('SET_AGENT_PID', data.info.agent_pid)
        roles.push('agent')
        if (data.is_admin == 1) {
          const backPermissionRoute = filterAdminRoutes(asyncRoutes)
          roles.push(data.username)
          data.permission = backPermissionRoute
          commit('SET_ROUTES_LIST', [...constantRoutes, ...backPermissionRoute])
          console.log(data)
        } else {
          // 后台的权限列表, 路由守卫
          // data.permission.forEach(per => {
          //   per.child = per.children
          // })

          const backPermissionRoute = data.permission = filterAsyncRoutes(data.permission)
          data.permission = backPermissionRoute
          console.log("is_admin == 1, 111111111111111111111123213")
          console.log(backPermissionRoute)
          backPermissionRoute.forEach(item => {
            if (item.path == '/work') {
              getJobCount().then(result => {
                let countList = result.data
                item.children.forEach(val => {
                  countList.forEach(count => {
                    if (count.name == val.meta.title) {
                      val.meta.title = val.meta.title + '(' + count.count + ')'
                    }
                  })
                })

              })
            }
          })
          commit('SET_ROUTES_LIST', [...constantRoutes, ...backPermissionRoute])
        }

        data.roles = roles
        commit('SET_ROLES', roles)
        resolve(data)
      }).catch(error => {
        console.log(error);
        reject('该用户没有配置权限角色3')
      })

    })
  },

  // user logout
  logout ({
            commit,
            state,
            dispatch
          }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then((data) => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        removeToken()
        resetRouter()
        sessionStorage.clear()
        // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
        dispatch('tagsView/delAllViews', null, {
          root: true
        })
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken ({
                commit
              }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // 获取用户未读消息统计数
  getNoticeCount ({
                    commit
                  }) {
    return new Promise((resolve, reject) => {
      getMessageCount().then(response => {
        if (response.code === 200) {
          console.log('notice~~~~~~~~~~~~~~~~~~~~~~~~~~')
          commit('SET_NOTICE_COUNT', response.data)
          resolve()
        } else {
          reject('网络错误')
        }
      }).catch(error => {
        console.log(error);
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
