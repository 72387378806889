import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

//
// └── store
//     ├── index.js          # 我们组装模块并导出 store 的地方
//     ├── actions.js        # 根级别的 action
//     ├── mutations.js      # 根级别的 mutation
//     └── modules
//         ├── cart.js       # 购物车模块
//         └── products.js   # 产品模块

//
// dispatch：含有异步操作，数据提交至 actions ，可用于向后台提交数据
//
// 写法示例： this.$store.dispatch('isLogin', true);
//
// commit：同步操作，数据提交至 mutations ，可用于登录成功后读取用户信息写到缓存里
//
// 写法示例： this.$store.commit('loginStatus', 1);
// ————————————————
// 版权声明：本文为CSDN博主「艾欢欢」的原创文章，遵循CC 4.0 BY-SA版权协议，转载请附上原文出处链接及本声明。
// 原文链接：https://blog.csdn.net/AiHuanhuan110/java/article/details/89160241

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

const store = new Vuex.Store({
  modules,
  getters
})

export default store
