import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'


// // 当设置 true 的时候该路由不会在侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
// hidden: true // (默认 false)
//
// //当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
// redirect: 'noRedirect'
//
// // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
// // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
// // 若你想不管路由下面的 children 声明的个数都显示你的根路由
// // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
// alwaysShow: true
//
// name: 'router-name' // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
// meta: {
//     roles: ['admin', 'editor'] // 设置该路由进入的权限，支持多个权限叠加
//     title: 'title' // 设置该路由在侧边栏和面包屑中展示的名字
//     icon: 'svg-name' // 设置该路由的图标，支持 svg-class，也支持 el-icon-x element-ui 的 icon
//     noCache: true // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
//     breadcrumb: false //  如果设置为false，则不会在breadcrumb面包屑中显示(默认 true)
//     affix: true // 如果设置为true，它则会固定在tags-view中(默认 false)
//
//     // 当路由设置了该属性，则会高亮相对应的侧边栏。
//     // 这在某些场景非常有用，比如：一个文章的列表页路由为：/article/list
//     // 点击文章进入文章详情页，这时候路由为/article/1，但你想在侧边栏高亮文章列表的路由，就可以进行如下设置
//     activeMenu: '/article/list'
// }

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export const constantRoutes = [{
	path: '/login',
	component: () =>
		import('@/views/login/index'),
	hidden: true
},
{
	path: '/auth-redirect',
	component: () =>
		import('@/views/login/auth-redirect'),
	hidden: true
},
	// {
	//   name: 'dashboard',
	//   path: '/',
	//   component: Layout,
	//   redirect: '/dashboard',
	//   children: [{
	//     path: 'dashboard',
	//     name: 'dashboard',
	//     component: () =>
	//       import('@/views/dashboard/index'),
	//     meta: {
	//       title: '首页',
	//       icon: 'el-icon-s-home'
	//     }
	//   }]
	// },
]
export const asyncRoutes = [{
	name: 'CDMHomePage',
	path: '/',
	component: Layout,
	redirect: '/CDMHomePage',
	children: [{
		path: 'CDMHomePage',
		name: 'CDMHomePage',
		component: () =>
			import('@/views/CDMHomePage/CDMHomePage'),
		meta: {
			title: '首页',
			icon: 'el-icon-s-home'
		}
	}]
},
{
	name: 'merchant',
	path: '/merchant',
	component: Layout,
	redirect: '/merchant/merchant',
	meta: {
		title: '商户管理',
		roles: ['admin'],
		icon: "el-icon-s-data"
	},
	children: [{
		path: 'agent',
		component: () =>
			import('@/views/CDMMerchant/merchant'), // Parent router-view
		name: 'agent',
		meta: {
			title: '商户管理',
			roles: ['admin']
		},
	},
		//   {
		// 	path: 'prescriptionManagement',
		// 	component: () =>
		// 	  import('@/views/product/prescriptionManagement'), // Parent router-view
		// 	name: 'prescriptionManagement',
		// 	meta: {
		// 	  title: '处方管理',
		// 	  roles: ['admin'],
		// 	  // icon: "el-icon-menu"
		// 	},
		// 	admin_hidden: true
		//   }
	]
},
// {
// 	name: 'product',
// 	path: '/product',
// 	component: Layout,
// 	redirect: '/product/productList',
// 	meta: {
// 		title: '商品管理',
// 		roles: ['admin'],
// 		icon: "el-icon-s-order"
// 	},
// 	children: [{
// 		path: 'productList',
// 		component: () =>
// 			import('@/views/CDMProduct/productList'), // Parent router-view
// 		name: 'productMag',
// 		meta: {
// 			title: '商品管理',
// 			roles: ['admin']
// 		},
// 	}, ]
// },
{
	name: 'healthFile',
	path: '/healthFile',
	component: Layout,
	redirect: '/healthFile/questionBank',
	meta: {
		title: '健康档案',
		roles: ['admin'],
		icon: "el-icon-s-order"
	},
	admin_hidden: true,
	children: [{
		path: 'questionBank',
		component: () =>
			import('@/views/CDMHealthFile/questionBank'), // Parent router-view
		name: 'questionBank',
		meta: {
			title: '题库',
			roles: ['admin']
		},
	},
	{
		path: 'examPaperLibrary',
		component: () =>
			import('@/views/CDMHealthFile/examPaperLibrary'), // Parent router-view
		name: 'examPaperLibrary',
		meta: {
			title: '试卷库',
			roles: ['admin'],
			// icon: "el-icon-menu"
		},
	},
	{
		path: 'addTestPaper',
		component: () =>
			import('@/views/CDMHealthFile/addTestPaper'), // Parent router-view
		name: 'addTestPaper',
		meta: {
			title: '新增试卷',
			roles: ['admin'],
			// icon: "el-icon-menu"
		},
		hidden: true,
	},
	]
},
{
	name: 'doctorHousekeeping',
	path: '/doctorHousekeeping',
	component: Layout,
	// redirect: '/doctorHousekeeping/bindingRecord',
	meta: {
		title: '医管家',
		roles: ['admin'],
		icon: "el-icon-s-custom"
	},
	admin_hidden: true,
	children: [{
		path: 'bindingRecord',
		name: 'bindingRecord',
		component: () => import('@/views/CDMDoctorHousekeeping/bindingRecord'),
		meta: {
			title: '绑定记录',
			roles: ['admin']
		},
	},
	{
		path: 'HealthPackage',
		name: 'HealthPackage',
		component: () => import('@/views/CDMDoctorHousekeeping/healthPackage'),
		meta: {
			title: '健康套餐',
			roles: ['admin']
		},
	}
	]
},
{
	name: 'patientManagement',
	path: '/patientManagement',
	component: Layout,
	redirect: '/patientManagement/CDMPatientManage',
	meta: {
		title: '患者档案',
		roles: ['admin'],
		icon: "el-icon-s-grid"
	},
	admin_hidden: true,
	children: [{
		path: 'CDMPatientManage',
		component: () =>
			import('@/views/CDMPatientManagement/CDMPatientManage'), // Parent router-view
		// name: 'CDMPatientManage',
		// import('@/views/product/profile'), // Parent router-view
		name: 'CDMPatientManage',
		meta: {
			title: '患者档案',
			roles: ['admin'],
			// icon: "el-icon-menu"
		},
	},]
},
{
	name: 'cluesToSettlement',
	path: '/cluesToSettlement',
	component: Layout,
	redirect: '/cluesToSettlement/CDMCluesToSettlement',
	meta: {
		title: '线索结算',
		roles: ['admin'],
		icon: "el-icon-video-camera-solid"
	},
	admin_hidden: true,
	children: [{
		path: 'CDMCluesToSettlement',
		component: () =>
			import('@/views/CDMCluesToSettlement/CDMCluesToSettlement'), // Parent router-view
		// name: 'CDMPatientManage',
		// import('@/views/product/profile'), // Parent router-view
		name: 'CDMCluesToSettlement',
		meta: {
			title: '线索结算',
			roles: ['admin'],
			// icon: "el-icon-menu"
		},
	},]
},
{
	name: 'underlyingConfiguration',
	path: '/underlyingConfiguration',
	component: Layout,
	redirect: '/onTheSource/CDMOnTheSource',
	meta: {
		title: '基础配置',
		roles: ['admin'],
		icon: "el-icon-sell"
	},
	admin_hidden: true,
	children: [
		{
			path: 'customerRelationsInfo',
			name: 'customerRelationsInfo',
			component: () => import('@/views/CDMCustomerRelations/customerRelationsInfo'),
			meta: {
				title: '客情信息',
				roles: ['admin']
			},
		},
		{
			path: 'CDMLevelOfReturn',
			component: () =>
				import('@/views/CDMLevelOfReturn/CDMLevelOfReturn'), // Parent router-view
			// name: 'CDMPatientManage',
			// import('@/views/product/profile'), // Parent router-view
			name: 'CDMLevelOfReturn',
			meta: {
				title: '回访级别',
				roles: ['admin'],
				// icon: "el-icon-menu"
			},
		},
		{
			path: 'CDMCustomerTags',
			component: () =>
				import('@/views/CDMCustomerTags/CDMCustomerTags'), // Parent router-view
			// name: 'CDMPatientManage',
			// import('@/views/product/profile'), // Parent router-view
			name: 'CDMCustomerTags',
			meta: {
				title: '客户标签',
				roles: ['admin'],
				// icon: "el-icon-menu"
			},
		},
		{
			path: 'CDMOnTheSource',
			component: () =>
				import('@/views/CDMOnTheSource/CDMOnTheSource'), // Parent router-view
			// name: 'CDMPatientManage',
			// import('@/views/product/profile'), // Parent router-view
			name: 'CDMOnTheSource',
			meta: {
				title: '投放来源',
				roles: ['admin'],
				// icon: "el-icon-menu"
			},
		},
		{
			path: 'CDMSourceChannel',
			component: () =>
				import('@/views/CDMOnTheSource/CDMSourceChannel'), // Parent router-view
			// name: 'CDMPatientManage',
			// import('@/views/product/profile'), // Parent router-view
			name: 'CDMSourceChannel',
			meta: {
				title: '投放渠道',
				roles: ['admin'],
				// icon: "el-icon-menu"
			},
		},
		{
			path: 'questionBank',
			component: () =>
				import('@/views/CDMHealthFile/questionBank'), // Parent router-view
			name: 'questionBank',
			meta: {
				title: '题库',
				roles: ['admin']
			},
		},
		{
			path: 'examPaperLibrary',
			component: () =>
				import('@/views/CDMHealthFile/examPaperLibrary'), // Parent router-view
			name: 'examPaperLibrary',
			meta: {
				title: '试卷库',
				roles: ['admin'],
				// icon: "el-icon-menu"
			},
		},
		{
			path: 'addTestPaper',
			component: () =>
				import('@/views/CDMHealthFile/addTestPaper'), // Parent router-view
			name: 'addTestPaper',
			meta: {
				title: '新增试卷',
				roles: ['admin'],
				// icon: "el-icon-menu"
			},
			hidden: true,
		},
	]
},
{
	name: 'articleManage',
	path: '/articleManage',
	alwaysShow: true,
	component: Layout,
	redirect: '/articleManage/articleList',
	meta: {
		title: '文章管理',
		roles: ['admin'],
		icon: "el-icon-reading",
	},
	admin_hidden: true,
	children: [{
		path: 'articleList',
		name: 'articleList',
		component: () =>
			import('@/views/CDMArticleManage/articleList'), // Parent router-view
		meta: {
			title: '文章列表',
			keepAlive: true,
		},
	}, {
		path: 'articleDetail',
		component: () =>
			import('@/views/CDMArticleManage/articleDetail'), // Parent router-view
		name: 'articleDetail',
		meta: {
			title: '文章详情'
		},
		hidden: true //是否显示在菜单
	}]
},

{
	name: 'customerRelations',
	path: '/customerRelations',
	component: Layout,
	alwaysShow: true,
	redirect: '/customerRelations/customerRelationsInfo',
	meta: {
		title: '客情管理',
		roles: ['admin'],
		icon: "el-icon-share",
	},
	admin_hidden: true,
	children: [{
		path: 'customerRelationsInfo',
		name: 'customerRelationsInfo',
		component: () => import('@/views/CDMCustomerRelations/customerRelationsInfo'),
		meta: {
			title: '客情信息',
			roles: ['admin']
		},
	}]
},
// {
//   name: 'CDMPatientManage',
//   path: '/CDMPatientManage',
//   component: Layout,
//   redirect: '/CDMPatientManage/CDMPatientManage',
//   meta: {
//     title: '患者管理',
//     roles: ['admin'],
//     icon: "el-icon-user-solid"
//   },
//   children: [{
//     path: 'CDMPatientManage',
//     name: 'CDMPatientManage',
//     component: () => import('@/views/CDMPatientManage/CDMPatientManage'),
//     meta: {
//       title: '患者管理',
//       roles: ['admin']
//     },
//   }]
// }
{
	name: 'order',
	path: '/order',
	component: Layout,
	redirect: '/order/order',
	meta: {
		title: '订单管理',
		roles: ['admin'],
		icon: "el-icon-s-claim"
	},
	admin_hidden: true,
	children: [
		{
			path: 'order',
			component: () =>
				import('@/views/CDMOrder/order'), // Parent router-view
			name: 'orderMag',
			meta: {
				title: '客情订单',
				roles: ['admin'],
				// icon: "el-icon-menu"
			},
		},
		{
			path: 'orderManager',
			component: () =>
				import('@/views/CDMOrder/orderManager'), // Parent router-view
			name: 'orderManager',
			meta: {
				title: '订单管理客情',
				roles: ['admin'],
				// icon: "el-icon-menu"
			},
		},
		// {
		// 	path: 'drugOrder',
		// 	component: () =>
		// 		import('@/views/CDMOrder/drugOrder'), // Parent router-view
		// 	name: 'drugOrder',
		// 	meta: {
		// 		title: '处方订单'
		// 	},
		// },
		{
			path: 'orderDetail',
			component: () =>
				import('@/views/CDMOrder/orderDetail'), // Parent router-view
			name: 'orderDetail',
			meta: {
				title: '订单详情'
			},
			hidden: true
		},
	]
},
// {
//   name: 'financialManagement',
//   path: '/financialManagement',
//   component: Layout,
//   redirect: '/financialManagement/detailOfIncomeAndExpenditure',
//   meta: {
// 	title: '财务管理',
// 	roles: ['admin'],
// 	icon: "el-icon-s-shop"
//   },
//   children: [{
// 	path: 'detailOfIncomeAndExpenditure',
// 	name: 'detailOfIncomeAndExpenditure',
// 	component: () => import('@/views/financialManagement/detailOfIncomeAndExpenditure'),
// 	meta: {
// 	  title: '收支明细',
// 	  roles: ['admin']
// 	},
//   }, {
// 	path: 'theRefundDetail',
// 	name: 'theRefundDetail',
// 	component: () => import('@/views/financialManagement/theRefundDetail'),
// 	meta: {
// 	  title: '退款管理',
// 	  roles: ['admin']
// 	},
//   }]
// },
// {
//   path: '/order',
//   component: Layout,
//   redirect: '/order/diaOrder',
//   name: 'order',
//   meta: {
// 	title: '订单管理',
// 	roles: ['admin'],
// 	icon: "el-icon-s-order"
//   },
//   children: [{
// 	path: 'drugOrder',
// 	component: () =>
// 	  import('@/views/order/drugOrder'), // Parent router-view
// 	name: 'drugOrder',
// 	meta: {
// 	  title: '药品订单'
// 	},
//   },
//   {
// 	path: 'specialOrder',
// 	component: () =>
// 	  import('@/views/order/specialOrder'), // Parent router-view
// 	name: 'specialOrder',
// 	meta: {
// 	  title: '特约订单'
// 	},
//   },
//   {
// 	path: 'drugOrderDetail/:id',
// 	component: () =>
// 	  import('@/views/order/drugOrderDetail'), // Parent router-view
// 	name: 'drugOrderDetail',
// 	meta: {
// 	  title: '药品订单详情'
// 	},
// 	hidden: true
//   },
//   {
// 	path: 'adjustOrder',
// 	component: () =>
// 	  import('@/views/order/adjustOrder'), // Parent router-view
// 	name: 'adjustOrder',
// 	meta: {
// 	  title: '订单改价'
// 	},
//   },
//   ]
// },
// {
// 	path: '/pharmacyManage',
// 	component: Layout,
// 	redirect: '/pharmacyManage/storeList',
// 	name: 'pharmacyManage',
// 	meta: {
// 		title: '药房管理',
// 		roles: ['admin', 'pharmacy'],
// 		icon: 'el-icon-s-order'
// 	},
// 	children: [
// 	  {
// 	path: 'storeList',
// 	component: () =>
// 	  import('@/views/pharmacyManage/storeList'), // Parent router-view
// 	name: 'storeList',
// 	meta: {
// 	  title: '普通商户',
// 	  roles: ['admin']
// 	},
//   },
// {
// 	path: 'pharmacyList',
// 	component: () =>
// 		import('@/views/CDMPharmacyManage/pharmacyList'), // Parent router-view
// 	name: 'pharmacyList',
// 	meta: {
// 		title: '药房管理',
// 		roles: ['admin']
// 	},
// },
//   {
// 	path: 'orderReview',
// 	component: () =>
// 	  import('@/views/pharmacyManage/orderReview'), // Parent router-view
// 	name: 'orderReview',
// 	meta: {
// 	  title: '订单审核',
// 	  roles: ['admin']
// 	},
//   },
//   {
// 	path: 'pharmacyOrderList',
// 	component: () => import('@/views/pharmacyManage/pharmacyOrderList'), // Parent router-view
// 	name: 'pharmacyOrderList',
// 	meta: {
// 	  title: '订单管理',
// 	  roles: ['admin']
// 	},
//   },
// {
// 	path: 'myPharmacy',
// 	component: () => import('@/views/CDMPharmacyManage/addPharmacy'), // Parent router-view
// 	name: 'myPharmacy',
// 	meta: {
// 		title: '药房信息',
// 		roles: ['pharmacy', 'admin']
// 	},
// 	admin_hidden: true
// 	// hidden: true   //是否显示在菜单
// },
// {
// 	path: 'addPharmacy',
// 	component: () =>
// 		import('@/views/CDMPharmacyManage/addPharmacy'), // Parent router-view
// 	name: 'addPharmacy',
// 	meta: {
// 		title: '新增药房',
// 		roles: ['admin']
// 	},
// 	hidden: true
// },
// {
// 	path: 'myPharmacy',
// 	component: () =>
// 		import('@/views/CDMPharmacyManage/addPharmacy'), // Parent router-view
// 	name: 'myPharmacy',
// 	admin_hidden: true,
// 	meta: {
// 		title: '药房信息',
// 		roles: ['pharmacy']
// 	},
// },
// {
// 	path: 'pharmacyDrugList',
// 	component: () =>
// 		import('@/views/CDMPharmacyManage/pharmacyDrugList'), // Parent router-view
// 	name: 'pharmacyDrugList',
// 	meta: {
// 		title: '药品维护',
// 		roles: ['admin']
// 	},
// 	hidden: true //是否显示在菜单
// },
// //药房端显示
// {
// 	path: 'pharmacyDrugLists',
// 	component: () =>
// 		import('@/views/CDMPharmacyManage/pharmacyDrugList'), // Parent router-view
// 	name: 'pharmacyDrugLists',
// 	meta: {
// 		title: '药品维护',
// 		roles: ['admin']
// 	},
// 	admin_hidden: true,
// 	// hidden: true //是否显示在菜单
// },
//   {
// 	path: 'pharmacyOrderDetail',
// 	component: () =>
// 	  import('@/views/pharmacyManage/pharmacyOrderDetail'), // Parent router-view
// 	name: 'pharmacyOrderDetail',
// 	meta: {
// 	  title: '订单详情',
// 	  roles: ['pharmacy']
// 	},
// 	hidden: true //是否显示在菜单
//   },
// 	]
// },


// {
// 	name: 'systemBasic',
// 	path: '/systemBasic',
// 	component: Layout,
// 	redirect: 'noRedirect',
// 	meta: {
// 		title: '系统基础',
// 		roles: ['admin', 'pharmacy'],
// 		icon: 'el-icon-s-tools'
// 	},
// 	children: [{
// 			path: 'orderLog',
// 			component: () =>
// 				import('@/views/CDMSystemBasic/orderLog'), // Parent router-view
// 			name: 'orderLog',
// 			meta: {
// 				title: '订单日志',
// 				roles: ['admin'],
// 			},
// 		},
// 		{
// 			path: 'exception',
// 			component: () =>
// 				import('@/views/CDMSystemBasic/exception'), // Parent router-view
// 			name: 'exception',
// 			meta: {
// 				title: '异常监控',
// 				roles: ['admin'],
// 			},
// 		},
// {
// 	path: 'noticeManagement',
// 	component: () =>
// 		import('@/views/CDMSystemBasic/noticeManagement'), // Parent router-view
// 	name: 'noticeManagement',
// 	meta: {
// 		title: '公告管理',
// 		roles: ['admin'],
// 	},
// },
// {
// 	path: 'noticeManagementCreateOrEdit',
// 	component: () =>
// 		import('@/views/CDMSystemBasic/noticeManagementCreateOrEdit'), // Parent router-view
// 	name: 'noticeManagement',
// 	hidden: true,
// 	meta: {
// 		title: '公告管理配置',
// 		roles: ['admin'],
// 	},
// }
// 	]
// },
// {
// 	name: 'ad',
// 	path: '/ad',
// 	component: Layout,
// 	redirect: 'noRedirect',
// 	meta: {
// 		title: '广告投放',
// 		roles: ['admin', 'pharmacy'],
// 		icon: 'el-icon-collection'
// 	},
// 	children: [{
// 		path: 'ocpc',
// 		component: () =>
// 			import('@/views/ad/ocpc'), // Parent router-view
// 		name: 'ocpc',
// 		meta: {
// 			title: 'OCPC',
// 			roles: ['admin'],
// 		},
// 	}]
// },

{
	name: 'clerkWork',
	path: '/clerkWork',
	component: Layout,
	meta: {
		title: '文员工作',
		roles: ['admin'],
		icon: "el-icon-menu"
	},
	admin_hidden: true,
	redirect: '/work/todayIncoming',
	children: [
		{
			path: 'threadInfo',
			component: () =>
				import('@/views/work/threadInfo'), // Parent router-view
			// name: 'CDMPatientManage',
			// import('@/views/product/profile'), // Parent router-view
			name: 'threadInfo',
			meta: {
				title: '线索信息',
				roles: ['admin'],
				// icon: "el-icon-menu"
			}
		},
		// {
		//   path: 'assignThreadRecord',
		//   component: () =>
		//     import('@/views/work/assignThreadRecord'), // Parent router-view
		//   // name: 'CDMPatientManage',
		//   // import('@/views/product/profile'), // Parent router-view
		//   name: 'assignThreadRecord',
		//   meta: {
		//     title: '分配线索记录',
		//     roles: ['admin'],
		//     // icon: "el-icon-menu"
		//   },
		// },
		{
			path: 'clerkOrderManager',
			component: () =>
				import('@/views/CDMOrder/orderManager'), // Parent router-view
			name: 'clerkOrderManager',
			meta: {
				title: '订单管理',
				roles: ['admin'],
				// icon: "el-icon-menu"
			},
		},
		{
			path: 'auditExpiredAt',
			name: 'auditExpiredAt',
			component: () =>
				import('@/views/work/auditExpiredAt'),
			meta: {
				title: '档案审核',
			}
		},
		{
			path: 'todayIncoming',
			name: 'todayIncoming',
			component: () =>
				import('@/views/work/todayIncoming'),
			meta: {
				title: '当日分线',
			}
		},
		{
			path: 'todayPreVisit',
			name: 'todayPreVisit',
			component: () =>
				import('@/views/work/todayPreVisit'),
			meta: {
				title: '当日待访',
			}
		},
		{
			path: 'todaySign',
			name: 'todaySign',
			component: () =>
				import('@/views/work/todaySign'),
			meta: {
				title: '当日签收',
			}
		},
		{
			path: 'todayReturnVisit',
			name: 'todayReturnVisit',
			component: () =>
				import('@/views/work/todayReturnVisit'),
			meta: {
				title: '当日回访',
			}
		},
		{
			path: 'previousNoAccess',
			name: 'previousNoAccess',
			component: () =>
				import('@/views/work/previousNoAccess'),
			meta: {
				title: '过期未访',
			}
		},
		{
			path: 'logisticsFollow',
			name: 'logisticsFollow',
			component: () =>
				import('@/views/work/logisticsFollow'),
			meta: {
				title: '客户订单',
			}
		},
		{
			path: 'videoCustomer',
			name: 'videoCustomer',
			component: () =>
				import('@/views/work/videoCustomer'),
			meta: {
				title: '视频客户',
			}
		},
		{
			path: 'notBuyCustomers',
			name: 'notBuyCustomers',
			component: () =>
				import('@/views/work/notBuyCustomer'),
			meta: {
				title: '未购客户',
			}
		},
		{
			path: 'buyCustomer',
			name: 'buyCustomer',
			component: () =>
				import('@/views/work/buyCustomer'),
			meta: {
				title: '已购客户',
			}
		},
		{
			path: 'nowUseCustomer',
			name: 'nowUseCustomer',
			component: () =>
				import('@/views/work/nowUseCustomer'),
			meta: {
				title: '在用客户',
			}
		},
		{
			path: 'preStopCustomer',
			name: 'preStopCustomer',
			component: () =>
				import('@/views/work/preStopCustomer'),
			meta: {
				title: '预停客户',
			}
		},
		{
			path: 'stopCustomer',
			name: 'stopCustomer',
			component: () =>
				import('@/views/work/stopCustomer'),
			meta: {
				title: '停用客户',
			}
		},
		{
			path: 'auditExpiredAt',
			name: 'auditExpiredAt',
			component: () =>
				import('@/views/work/auditExpiredAt'),
			meta: {
				title: '档案审核',
			}
		},
	]
},

{
	name: 'work',
	path: '/work',
	component: Layout,
	meta: {
		title: '客服工作',
		roles: ['admin'],
		icon: "el-icon-menu"
	},
	admin_hidden: true,
	redirect: '/work/todayIncoming',
	children: [
		{
			path: 'clientAll',
			component: () =>
				import('@/views/work/clientAll'), // Parent router-view
			name: 'clientAll',
			meta: {
				title: '全部客户',
				roles: ['admin'],
				// icon: "el-icon-menu"
			},
		},
		{
			path: 'threadInfo',
			component: () =>
				import('@/views/work/threadInfo'), // Parent router-view
			// name: 'CDMPatientManage',
			// import('@/views/product/profile'), // Parent router-view
			name: 'threadInfo',
			meta: {
				title: '线索信息',
				roles: ['admin'],
				// icon: "el-icon-menu"
			},
		},
		{
			path: 'clerkOrderManager',
			component: () =>
				import('@/views/CDMOrder/orderManager'), // Parent router-view
			name: 'clerkOrderManager',
			meta: {
				title: '订单管理',
				roles: ['admin'],
				// icon: "el-icon-menu"
			},
		},
		{
			path: 'todayIncoming',
			name: 'todayIncoming',
			component: () =>
				import('@/views/work/todayIncoming'),
			meta: {
				title: '当日分线',
			}
		},
		{
			path: 'todayPreVisit',
			name: 'todayPreVisit',
			component: () =>
				import('@/views/work/todayPreVisit'),
			meta: {
				title: '当日待访',
			}
		},
		{
			path: 'todaySign',
			name: 'todaySign',
			component: () =>
				import('@/views/work/todaySign'),
			meta: {
				title: '当日签收',
			}
		},
		{
			path: 'todayReturnVisit',
			name: 'todayReturnVisit',
			component: () =>
				import('@/views/work/todayReturnVisit'),
			meta: {
				title: '当日回访',
			}
		},
		{
			path: 'previousNoAccess',
			name: 'previousNoAccess',
			component: () =>
				import('@/views/work/previousNoAccess'),
			meta: {
				title: '过期未访',
			}
		},
		{
			path: 'logisticsFollow',
			name: 'logisticsFollow',
			component: () =>
				import('@/views/work/logisticsFollow'),
			meta: {
				title: '客户订单',
			}
		},
		{
			path: 'videoCustomer',
			name: 'videoCustomer',
			component: () =>
				import('@/views/work/videoCustomer'),
			meta: {
				title: '视频客户',
			}
		},
		{
			path: 'notBuyCustomers',
			name: 'notBuyCustomers',
			component: () =>
				import('@/views/work/notBuyCustomer'),
			meta: {
				title: '未购客户',
			}
		},
		{
			path: 'buyCustomer',
			name: 'buyCustomer',
			component: () =>
				import('@/views/work/buyCustomer'),
			meta: {
				title: '已购客户',
			}
		},
		{
			path: 'nowUseCustomer',
			name: 'nowUseCustomer',
			component: () =>
				import('@/views/work/nowUseCustomer'),
			meta: {
				title: '在用客户',
			}
		},
		{
			path: 'preStopCustomer',
			name: 'preStopCustomer',
			component: () =>
				import('@/views/work/preStopCustomer'),
			meta: {
				title: '预停客户',
			}
		},
		{
			path: 'stopCustomer',
			name: 'stopCustomer',
			component: () =>
				import('@/views/work/stopCustomer'),
			meta: {
				title: '停用客户',
			}
		}]
},
{
	path: '/keQinManager',
	name: 'keQinManager',
	component: Layout,
	redirect: '/keQinManager/callRecord',
	meta: {
		title: '慢病报表',
		roles: ['admin'],
		icon: "el-icon-s-tools"
	},
	admin_hidden: true,
	children: [
		{
			path: 'recordReport',
			name: 'recordReport',
			component: () =>
				import('@/views/keQinManager/recordReport'),

			meta: {
				title: '档案报表',
				roles: ['admin']
			}
		},
		{
			path: 'todayBranchingReport',
			name: 'todayBranchingReport',
			component: () =>
				import('@/views/keQinManager/todayBranchingReport'),

			meta: {
				title: '进线日报',
				roles: ['admin']
			}
		},
		{
			path: 'todaySellReport',
			name: 'todaySellReport',
			component: () =>
				import('@/views/keQinManager/todaySellReport'),

			meta: {
				title: '销售日报',
				roles: ['admin']
			}
		},
		{
			path: 'todayMediaReport',
			name: 'todayMediaReport',
			component: () =>
				import('@/views/keQinManager/todayMediaReport'),

			meta: {
				title: '媒体日报',
				roles: ['admin']
			}
		},
		{
			path: 'consumeSign',
			name: 'consumeSign',
			component: () =>
				import('@/views/keQinManager/consumeSign'),

			meta: {
				title: '消耗签收',
				roles: ['admin']
			}
		},
		{
			path: 'repurchaseRate',
			name: 'repurchaseRate',
			component: () =>
				import('@/views/keQinManager/repurchaseRate'),

			meta: {
				title: '复购占比',
				roles: ['admin']
			}
		},
		{
			path: 'callRecords',
			name: 'callRecords',
			component: () =>
				import('@/views/keQinManager/callRecord'),

			meta: {
				title: '通话记录',
				roles: ['admin']
			}
		},
		{
			path: 'phoneStatus',
			name: 'phoneStatus',
			component: () =>
				import('@/views/keQinManager/phoneStatus'),

			meta: {
				title: '分机情况',
				roles: ['admin']
			}
		},
		{
			path: 'saleRank',
			name: 'saleRank',
			component: () =>
				import('@/views/keQinManager/saleRank'),

			meta: {
				title: '销售排行',
				roles: ['admin']
			}
		}
	]

},
{
	path: '/logRecord',
	name: 'logRecord',
	component: Layout,
	redirect: '/logRecord/threadLog',
	meta: {
		title: '日志记录',
		roles: ['admin'],
		icon: "el-icon-s-tools"
	},
	admin_hidden: true,
	children: [
		{
			path: 'threadLog',
			name: 'threadLog',
			component: () =>
				import('@/views/logRecord/threadLog'),
			meta: {
				title: '线索分配日志',
				roles: ['admin']
			}
		},
		{
			path: 'loginLog',
			name: 'loginLog',
			component: () =>
				import('@/views/logRecord/loginLog'),

			meta: {
				title: '登录日志',
				roles: ['admin']
			}
		},
		{
			path: 'repeatLineLog',
			name: 'repeatLineLog',
			component: () =>
				import('@/views/logRecord/repeatLineLog'),

			meta: {
				title: '重线日志',
				roles: ['admin']
			}
		},
	]

},
{
	path: '/noteManage',
	name: 'noteManage',
	component: Layout,
	redirect: 'noRedirect',
	meta: {
		title: '短信管理',
		roles: ['admin'],
		icon: "el-icon-s-comment",
	},
	alwaysShow: true,
	children: [{
		path: 'noteTemplate',
		name: 'noteTemplate',
		component: () =>
			import('@/views/noteManage/noteTemplate'),
		meta: {
			title: '短信模板',
			roles: ['admin']
		}
	}]
},

{
	path: '/auth',
	name: 'auth',
	component: Layout,
	redirect: 'noRedirect',
	meta: {
		title: '权限管理',
		roles: ['admin'],
		icon: "el-icon-lock"
	},
	children: [{
		path: 'user',
		name: 'user',
		component: () =>
			import('@/views/CDMAuthor/account'),
		meta: {
			title: '账号管理',
			roles: ['admin']
		}
	}, {
		path: 'role',
		name: 'role',
		component: () =>
			import('@/views/CDMAuthor/role'),
		meta: {
			title: '角色管理',
			roles: ['admin'],
		}
	}, {
		path: 'permission',
		name: 'permission',
		component: () =>
			import('@/views/CDMAuthor/auth'),
		meta: {
			title: '权限管理'
		}
	}]
},
// {
// 	path: '/finance',
// 	name: 'finance',
// 	component: Layout,
// 	redirect: 'noRedirect',
// 	meta: {
// 		title: '财务管理',
// 		roles: ['admin'],
// 		icon: "el-icon-money"
// 	},
// 	children: [{
// 		path: 'pay',
// 		name: 'pay',
// 		component: () =>
// 			import('@/views/CDMFinance/pay'),

// 		meta: {
// 			title: '财务管理',
// 			roles: ['admin']
// 		}
// 	},
// 	{
//   path: 'cashOperation',
//   name: 'cashOperation',
//   component: () =>
//     import('@/views/CDMFinance/cashOperation'),

//   meta: {
//     title: '提现明细',
//     roles: ['admin']
//   }
// }]
// },
{
	path: '/configure',
	name: 'configure',
	component: Layout,
	redirect: '/CDMConfigure/inquiryConfigure',
	meta: {
		title: '系统配置',
		roles: ['admin'],
		icon: "el-icon-s-tools"
	},
	admin_hidden: true,
	children: [{
		path: 'inquiryConfigure',
		name: 'inquiryConfigure',
		component: () =>
			import('@/views/CDMConfigure/systemSetting'),

		meta: {
			title: '系统配置',
			roles: ['admin']
		}
	},
		// {
		// 	path: 'guestConfigure',
		// 	name: 'guestConfigure',
		// 	component: () =>
		// 		import('@/views/CDMConfigure/guestConfigure'),

		// 	meta: {
		// 		title: '客情配置',
		// 		roles: ['admin']
		// 	}
		// }
	]

},

]


const createRouter = () => new Router({
	// mode: 'history', // require service support
	scrollBehavior: () => ({
		y: 0
	}),
	routes: constantRoutes
})

const router = createRouter()
// router.addRoutes(dynamicRouter)

export const routesMap = parseMap(asyncRoutes)

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}


export function parseMap(arr) {
	let routesMap = {}

	function flat(arr) {
		return arr.reduce((pre, cur) => {

			if (cur.name) {
				routesMap[cur.name] = cur
			}
			return pre.concat(Array.isArray(cur.children) ? flat(cur.children) : cur)
		}, [])
	}

	flat(arr)
	console.log(routesMap)
	return routesMap
}
export default router
